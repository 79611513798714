import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { cover } from "polished"

import { TaliHappy, TaliCurious } from "../../lib/icons/Tali"

const StyledTali = styled.section`
  display: grid;
  justify-content: start;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  /* padding-right: ${({ theme }) => theme.spacings.fluid.s}vw; */
  padding-left: ${({ theme }) => theme.spacings.fluid.small}vw;
  padding-bottom: ${({ theme }) => theme.spacings.fluid.small}vh;
  margin-left: 12px;

  &[data-float="true"] {
    position: fixed;
    z-index: 20;
    left: 0;
  }

  &[data-position="top"] {
    top: 1vh;
    grid-template-areas:
      "tali ."
      ". speechbubble";
    align-content: start;
  }

  &[data-position="bottom"] {
    bottom: 0;
    grid-template-areas:
      ". speechbubble"
      "tali .";
    align-content: end;
  }

  &[data-backdrop="true"] {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;

    &:before {
      ${cover()};
      content: "";
      background: radial-gradient(
        55.67% 98.97% at 40% 70%,
        #00273d 0%,
        rgba(0, 39, 61, 0.8) 100%
      );
    }
  }

  #tali {
    grid-area: tali;
    width: ${({ size }) => size * 100}px;
    height: ${({ size }) => size * 100}px;
  }
`

const SpeechBubble = styled.article`
  border-radius: 7vw;
  grid-row: 1 / -1;
  z-index: 3;
  padding: ${({ theme }) => theme.spacings.fluid.regular}vh
    ${({ theme }) => theme.spacings.fluid.medium}vw;
  overflow: auto;
  height: 100%;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-area: speechbubble;
    max-height: calc(100vh - 100px);
  }

  &[data-style="white"] {
    background-color: white;
  }

  &[data-style="glow"] {
    background: linear-gradient(
      to right,
      #5bd1ec 20.07%,
      rgba(91, 209, 236, 0) 110.41%
    );
    /* filter: drop-shadow(0px 10px 250px rgba(91, 209, 236, 0.7)); causes rendering issues on iOS ... */
  }

  &[data-position="top"] {
    border-top-left-radius: 0;
  }

  &[data-position="bottom"] {
    border-bottom-left-radius: 0;
  }

  .content {
    h1,
    h2,
    h3 {
      &:first-child {
        margin-top: 0;
      }
    }

    nav {
      margin-top: ${({ theme }) => theme.spacings.fluid.regular}vh;
      display: flex;

      & > * {
        margin-right: ${({ theme }) => theme.spacings.fluid.small}vw;
      }
    }

    .attempts {
      margin-right: 0;
    }
  }
`

const TaliDisplay = ({
  size = 1,
  mood = "happy",
  children,
  title,
  buttonLabel,
  buttonAction,
  secondaryButton,
  defaultRef,
  background,
  backdrop = false,
  position = "bottom",
  float = true,
  messageStyle = "white",
}) => {
  return (
    <StyledTali
      size={size}
      data-float={float}
      data-position={position}
      data-backdrop={backdrop}
      className="tali"
    >
      {mood === "happy" && <TaliHappy />}
      {mood === "curious" && <TaliCurious />}
      {children && (
        <SpeechBubble data-position={position} data-style={messageStyle}>
          <div className="content">
            {title && <h2>{title}</h2>}
            {children}
            {buttonLabel && buttonAction && (
              <nav>
                {secondaryButton}
                <button
                  className={`button${
                    mood === "happy" ? " button--good" : ""
                  } button--medium`}
                  onClick={buttonAction}
                  ref={defaultRef}
                >
                  {buttonLabel}
                </button>
              </nav>
            )}
          </div>
        </SpeechBubble>
      )}
      {background}
    </StyledTali>
  )
}

TaliDisplay.propTypes = {
  size: PropTypes.number,
  mood: PropTypes.oneOf(["happy", "curious"]),
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  buttonLabel: PropTypes.string,
  buttonAction: PropTypes.func,
  defaultRef: PropTypes.any,
  background: PropTypes.node,
  backdrop: PropTypes.bool,
  position: PropTypes.oneOf(["top", "bottom"]),
  float: PropTypes.bool,
  messageStyle: PropTypes.oneOf(["white", "glow"]),
}

export default TaliDisplay
