// Basic libraries
import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"
import produce from "immer"
import { ToastContainer } from "react-toastify"
import { isMobileSafari } from "react-device-detect"

// Used components
import Navigation from "../../../components/navigation"
import Content from "../../../components/content"
import Seo from "../../../components/seo"
import CurrentUser from "../../../components/CurrentUser"
import DailyProgress from "../../../components/DailyProgress"
import Tali from "../../../components/Tali"

// Images & Styling
import { BackButton } from "../../../styles/buttons"
import ArrowIcon from "../../../lib/icons/Arrow"
import { DailyTargetLayout } from "../../../styles/common"
import { SpaceButton } from "../../../styles/buttons"

// Fetching data
import {
  updateCurrentStudentData,
  StudentActivity,
} from "../../../services/helpers"

// Globals
import { useStudentStore } from "../../../../store"
import { SECONDS_PER_DAY, UI_EVENT_NAMES } from "../../../const_values"

const { ALL } = UI_EVENT_NAMES

// Browser check
const isBrowser = typeof window !== "undefined"

// component
class DailyTargetPage extends Component {
  constructor() {
    super()

    const dataPerDay = {
      isCurrentDay: false,
      isFutureDay: true,
      realLearnedWords: 0,
      realLearnedMins: 0,
      targetLearnedWords: useStudentStore.getState().student?.dailyTarget ?? 10,
    }

    this.state = {
      window: {
        width: 0,
        height: 0,
      },
      urlPath: isBrowser ? window.location.pathname : null,
      hasAchievedDaily: false,
      data: {
        wordsTotalNumber: 0,
        wordsTotalMin: 0,
        dailyData: Array.from({ length: 5 }).map((_) => ({ ...dataPerDay })),
      },
    }
    this.handleResize = this.handleResize.bind(this)
    this.logPageLeaving = this.logPageLeaving.bind(this)
    this.finishPageLoading = this.finishPageLoading.bind(this)
    this.checkStudentData = this.checkStudentData.bind(this)
    this.renderSectionDynamically = this.renderSectionDynamically.bind(this)
    this.renderMandatoryTali = this.renderMandatoryTali.bind(this)
    this.renderVoluntaryTali = this.renderVoluntaryTali.bind(this)
    this.setInputSessions = this.setInputSessions.bind(this)
  }
  // Fehlerschwerpunkt: 3 größten Zahlen in mistakeprognosis --> auf Fehlerkategorie mappen...

  handleResize = () => {
    this.setState({
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    })
  }

  renderSectionDynamically = () => {
    const { hasAchievedDaily } = this.state
    const today = new Date().getDay()

    return hasAchievedDaily || today < 1 || today > 5
      ? this.renderVoluntaryTali()
      : this.renderMandatoryTali()
  }

  renderVoluntaryTali = () => {
    const { dailyData } = this.state.data
    const wordsTotalNumber =
      dailyData?.filter((day) => day.isCurrentDay)[0]?.targetLearnedWords ?? 0

    return (
      <Tali mood="happy" position="top" float={false} messageStyle="glow">
        <h3>Du hast dein Tagesziel erreicht:</h3>
        <p>{wordsTotalNumber} Wörter</p>
        <Link className="button button--huge exercise" to="/exercise">
          Weiter tanken!
        </Link>
        <SpaceButton
          className="space-button"
          onClick={() => navigate("./space")}
        >
          Losfliegen!
        </SpaceButton>
      </Tali>
    )
  }

  renderMandatoryTali = () => {
    const { wordsTotalNumber, wordsTotalMin } = this.state.data
    const wordsTotalMinRounded = wordsTotalMin.toFixed(1)
    return (
      <Tali mood="happy" position="top" float={false} messageStyle="glow">
        <h3>Geschafft diese Woche:</h3>
        <p>{wordsTotalNumber} Wörter</p>
        <p>{wordsTotalMinRounded} Minuten</p>
        <Link className="button button--huge exercise" to="/exercise">
          Lass uns auftanken für heute!
        </Link>
      </Tali>
    )
  }

  checkStudentData = async () => {
    try {
      const login = isBrowser
        ? new URLSearchParams(window.location.search).get("login")
        : ""
      const updateSuccessResult = await updateCurrentStudentData(login)

      if (!updateSuccessResult.success && isBrowser) {
        window.localStorage.removeItem("jwt_student")
        navigate("/student", { replace: true })
      } else if (updateSuccessResult.success) {
        this.setInputSessions()
      }
    } catch (error) {
      console.error(error)
      if (isBrowser) {
        window.localStorage.removeItem("jwt_student")
        navigate("/student", { replace: true })
      }
    }
  }

  finishPageLoading = async () => {
    await this.checkStudentData()

    if (isBrowser) {
      try {
        const visitActivity = new StudentActivity(
          this.state.urlPath,
          ALL.PAGE_VISITED,
          useStudentStore.getState().student.id
        )
        visitActivity.writeToDB()
      } catch (e) {
        console.error("failed to log page visiting: ", e)
      }
    }
  }

  logPageLeaving = async (event) => {
    try {
      const leaveActivity = new StudentActivity(
        this.state.urlPath,
        ALL.PAGE_LEAVED,
        useStudentStore.getState().student.id
      )
      await leaveActivity.writeToDB()
    } catch (e) {
      console.error("failed to log page leaving: ", e)
    }
    if (event) {
      event.returnValue = ""
    }
    return ""
  }

  componentWillMount() {
    this.finishPageLoading()
  }

  componentDidMount() {
    if (isBrowser) {
      window.addEventListener(
        isMobileSafari ? "pagehide" : "beforeunload",
        this.logPageLeaving
      )
      this.setState(
        {
          window: {
            width: window.innerWidth,
            height: window.innerHeight,
          },
        },
        () => window.addEventListener("resize", this.handleResize)
      )
    }
  }

  componentWillUnmount() {
    if (isBrowser) {
      window.removeEventListener(
        isMobileSafari ? "pagehide" : "beforeunload",
        this.logPageLeaving
      )

      this.logPageLeaving()

      window.addEventListener("resize", this.handleResize)
    }
  }

  setInputSessions = () => {
    const inputSessions = [
      ...useStudentStore.getState().student.inputSessionsByStudent.nodes,
    ].sort((a, b) => a.startTime - b.startTime)
    const dailyTarget = useStudentStore.getState().student.dailyTarget

    console.log("input sessions: ", inputSessions)
    this.setState(
      produce((draft) => {
        const { data } = draft
        const currentDate = new Date()
        const today = currentDate.getDay()

        if (today > 0 && today < 6) {
          data.dailyData[today - 1].isCurrentDay = true
          data.dailyData[today - 1].isFutureDay = false
          data.dailyData[today - 1].targetLearnedWords = dailyTarget

          const todayStart = new Date(currentDate)
          todayStart.setHours(0)
          todayStart.setMinutes(0)
          todayStart.setSeconds(0)

          const weekBeginningTimestamp =
            todayStart.getTime() - SECONDS_PER_DAY * 1000 * (today - 1)
          const weekBeginning = new Date(weekBeginningTimestamp)

          let i = 0
          while (i < today) {
            data.dailyData[i].isFutureDay = false
            i++
          }

          inputSessions.forEach((s) => {
            const sDateObj = new Date()
            sDateObj.setTime(s.startTime)

            const sWeekDay = sDateObj.getDay()
            const sessionTime = sDateObj.getTime()
            const mondayTime = weekBeginning.getTime()

            if (this.isCurrentWeek(mondayTime, sessionTime)) {
              data.dailyData[sWeekDay - 1].realLearnedMins +=
                s.durationMs / (1000 * 60)
              if (s.correct) {
                data.dailyData[sWeekDay - 1].realLearnedWords += 1
              }
            }
          })

          data.dailyData.forEach((d, i) => {
            data.wordsTotalNumber += d.realLearnedWords
            data.wordsTotalMin += d.realLearnedMins

            if (i < today) {
              data.dailyData[i].isFutureDay = false
            }
          })

          if (data.dailyData[today - 1].realLearnedWords >= dailyTarget) {
            draft.hasAchievedDaily = true
          } else {
            this.forceUpdate()
          }
        }
      })
    )
  }

  componentDidUpdate() {
    console.log("current state:", this.state)
  }

  isCurrentWeek = (mondayTime, sessionTime) => {
    const timestampDifference = sessionTime - mondayTime

    return (
      timestampDifference >= 0 &&
      timestampDifference < 5 * SECONDS_PER_DAY * 1000
    )
  }

  render() {
    const windowHeight = this.state.window.height
    const { dailyData } = this.state.data
    console.log("DAILY-DATA: ", dailyData)

    return (
      <div>
        <ToastContainer theme="colored" autoClose={2000} />
        <Seo title="Meine Ziele" />
        <Navigation>
          <BackButton as={Link} to="/" alt="Zurück zum Profil">
            <ArrowIcon />
          </BackButton>
          <CurrentUser />
        </Navigation>
        <Content height={windowHeight}>
          {useStudentStore.getState().student.id && (
            <DailyTargetLayout>
              <section className="title-area">
                <h1>Meine Ziele</h1>
                <h2>Schreiben lernen</h2>
              </section>
              <section className="planets-area">
                <DailyProgress weekData={dailyData} scaler={2} />
              </section>
              <section className="tali-area">
                {this.renderSectionDynamically()}
              </section>
            </DailyTargetLayout>
          )}
        </Content>
      </div>
    )
  }
}
// component-end

export default DailyTargetPage
