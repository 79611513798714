import React from "react"
import styled, { keyframes } from "styled-components"

export const blink = keyframes`
  0% {
    transform: scaleY(1);
  }
  1% {
    transform: scaleY(0);
  }
  2% {
    transform: scaleY(1);
  }
`

export const levitate = keyframes`
  0% {
    transform: translateY(0)
  }
  33.333% {
    transform: translateY(-10%)
  }
  66.666% {
    transform: translateY(5%)
  }
  100% {
    transform: translateY(0)
  }
`

const StyledTaliSvg = styled.svg`
  animation: ${levitate} 15s linear infinite;

  #tali__left-eye {
    animation: ${blink} 30s ease infinite;
    transform-origin: 220px 220px;
  }

  #tali__right-eye {
    animation: ${blink} 30s 1s ease infinite;
    transform-origin: 200px 185px;
  }
`

export const TaliHappy = props => (
  <StyledTaliSvg viewBox="0 0 396 396" fill="none" id="tali" {...props}>
    <path
      id="tali__head"
      d="M308.485 117.471c44.475 61.019 31.064 146.539-29.956 191.014-61.019 44.475-146.539 31.064-191.014-29.956C43.04 217.51 56.45 131.99 117.47 87.515 178.49 43.04 264.01 56.45 308.485 117.47z"
      stroke="#5BD1EC"
      strokeWidth={16}
    />
    <g id="tali__body" fill="#5BD1EC">
      <path
        id="tali__right-leg"
        d="M102.187 325.992c1.341-6.408 14.902-9.836 19.522-10.432l-.894-6.11c-2.236.298-22.205 3.577-24.59 15.35-1.639 7.451 4.322 15.647 17.735 25.036-2.981.894-6.11 1.937-8.197 3.129-5.216 2.981-2.831 9.985-.894 8.793 1.788-1.193 18.479-11.326 19.671-11.922-3.278-3.279-24.142-14.754-22.353-23.844z"
      />
      <path
        id="tali__right-arm"
        d="M69.216 97.087s1.49-3.577.15-5.812c-2.236-3.726-12.519-4.47-16.84 1.788-1.64 2.385.298 7.899 2.086 10.283-32.487 53.798 3.576 113.706 4.023 114.302L64 214.369c-.298-.596-34.275-57.076-4.172-107.446 2.98-.895 7.749.149 9.388-3.13 2.087-4.322 0-6.706 0-6.706z"
      />
      <path
        id="tali__left-arm"
        d="M299.945 325.283c.894-7.004-6.557-12.071-10.581-10.283-2.384 1.044-3.576 5.365-3.576 5.365s-3.279 1.491-3.577 4.62c-.298 2.981 2.533 4.769 4.173 5.514-3.279 8.494-11.177 23.844-18.032 23.248-.447 0-.745-.149-1.043-.745-3.428-4.918 0-24.589 3.427-36.958l-5.961-1.64c-1.49 5.365-8.792 32.935-2.533 42.174 1.192 1.789 2.831 2.832 4.769 3.279.298 0 .596.149.894.149 12.816 1.192 22.353-21.609 24.44-27.272 3.725-.745 7.153-4.172 7.6-7.451z"
      />
      <path
        id="tali__left-leg"
        d="M213.659 332.733l-5.067 3.726c0 .149 6.706 9.239 3.13 15.647-5.961 11.028-35.617 7.75-35.617 7.75.149 1.341 3.278 20.565 3.577 22.8.298 2.087 7.153-1.192 6.11-7.153-.448-2.682-1.938-5.961-3.279-8.941 19.075.298 30.55-3.428 34.872-11.475 5.365-9.985-3.428-21.907-3.726-22.354z"
      />
    </g>
    <g id="tali__face" fill="#5BD1EC">
      <path
        id="tali__mouth"
        d="M172.524 279.876c-20.011-6.946-30.965-28.241-25.345-48.368l75.207 26.107c-8.059 19.281-29.852 29.207-49.862 22.261z"
        stroke="#5BD1EC"
        strokeWidth={5}
      />
      <path
        id="tali__right-eye"
        d="M140.89 199.156c-4.415-1.653-6.67-6.609-5.016-11.024l3.816-10.187c1.654-4.415 6.61-6.67 11.024-5.016 4.415 1.654 6.67 6.609 5.016 11.024l-3.816 10.188c-1.654 4.415-6.609 6.669-11.024 5.015z"
      />
      <path
        id="tali__left-eye"
        d="M245.432 237.444c-4.417-1.647-6.68-6.599-5.033-11.016l3.801-10.193c1.647-4.418 6.599-6.68 11.016-5.033 4.418 1.647 6.68 6.599 5.033 11.016l-3.801 10.194c-1.647 4.417-6.599 6.679-11.016 5.032z"
      />
    </g>
  </StyledTaliSvg>
)

export const TaliCurious = props => (
  <StyledTaliSvg viewBox="0 0 396 396" fill="none" id="tali" {...props}>
    <path
      id="tali__head"
      d="M307.634 119.814c43.732 59.999 30.544 144.089-29.454 187.82-59.999 43.732-144.089 30.544-187.82-29.454-43.732-59.999-30.544-144.089 29.454-187.82 59.999-43.732 144.089-30.544 187.82 29.454z"
      stroke="#5BD1EC"
      strokeWidth={16}
    />
    <g id="tali__body" fill="#5BD1EC">
      <path
        id="tali__right-arm"
        d="M70.375 325.34l-23.374-18.539c-.806-.644-2.096-.483-2.902.323-.645.806-.484 2.096.322 2.902l15.315 12.251c-9.35-4.191-15.798-8.06-18.539-9.672-.967-.645-2.095-.323-2.74.645-.645.967-.323 2.257.645 2.901 2.579 1.612 7.899 4.675 15.637 8.383-7.416-1.773-13.542-3.546-16.444-4.514-1.128-.322-2.095.162-2.579 1.29-.322 1.128.161 2.257 1.29 2.579 3.546 1.129 11.607 3.547 21.279 5.642-5.803.323-12.896.323-20.312-.806-1.128-.161-2.096.645-2.257 1.612-.161 1.129.645 2.096 1.773 2.257 12.413 1.612 23.375.806 29.824 0-2.58 2.741-6.126 6.126-9.512 8.544-.806.645-1.128 1.774-.644 2.741.644.967 1.934 1.289 2.901.645 5.804-4.031 11.607-10.64 13.38-12.736 14.186-14.347 26.438-52.391 26.922-54.004l-6.449-2.095c.162.161-11.123 35.143-23.536 49.651z"
      />
      <path
        id="tali__left-arm"
        d="M385.268 273.373c-.323-.967-1.451-1.451-2.418-1.29a80.507 80.507 0 01-10.962 1.773c4.997-1.45 9.027-3.224 11.123-4.352.967-.484 1.451-1.773.806-2.902-.484-.967-1.612-1.29-2.579-.806-2.419 1.129-7.093 3.224-13.219 4.836 4.352-1.934 8.866-4.675 12.896-8.382.806-.806.967-2.096.161-2.902-.806-.806-1.934-.806-2.74-.161-10.479 9.511-25.309 12.251-29.662 12.896-.967-1.934-2.257-7.093 4.514-14.508.645-.806.645-2.096 0-2.741a2.044 2.044 0 00-2.902 0c-10.317 11.446-5.32 19.184-4.191 20.473-17.249 34.176-26.438 36.594-29.501 35.949-6.609-1.128-10.317-17.41-11.123-25.148l-6.771.645c.323 2.902 3.386 28.694 16.766 31.112 10.478 1.935 22.246-10.8 36.755-39.656 4.03 1.612 8.06 2.579 11.768 3.224 7.899 1.451 14.508 1.29 17.893.967 1.129-.161 1.935-1.128 1.774-2.257-.162-1.128-1.129-1.773-2.096-1.773-2.579.161-7.899.323-14.186-.483 8.221-.162 13.864-1.29 16.765-2.096.806 0 1.451-1.29 1.129-2.418z"
      />
      <path
        id="tali__right-leg"
        d="M169.839 326.308c0 .322.806 33.047-.161 55.777-3.224-.806-7.577-1.612-10.962-1.773-6.449-.161-10.156 6.448-7.899 6.448h24.825c1.935 0 .806-58.84.806-60.613l-6.609.161z"
      />
      <path
        id="tali__left-leg"
        d="M233.353 380.311c-3.385 0-7.093.484-10.317.806 9.995-22.569 10.156-52.714 10.156-54.165h-6.771c-.161 35.788-13.058 59.485-13.058 59.485h29.017c2.902 0-1.289-6.287-9.027-6.126z"
      />
    </g>
    <g id="tali__face" fill="#5BD1EC">
      <path
        id="tali__mouth"
        d="M199.974 275.763c-21.3 0-38.728-16.62-40.019-37.595h80.037c-1.291 20.975-18.719 37.595-40.018 37.595z"
        stroke="#5BD1EC"
        strokeWidth={5}
      />
      <path
        id="tali__right-eye"
        d="M143.334 209.537c-4.737-.115-8.511-4.077-8.395-8.814l.265-10.93c.115-4.737 4.077-8.511 8.814-8.396 4.737.115 8.511 4.077 8.396 8.814l-.266 10.93c-.115 4.737-4.077 8.511-8.814 8.396z"
      />
      <path
        id="tali__left-eye"
        d="M255.211 211.434c-4.737-.108-8.517-4.064-8.409-8.801l.249-10.93c.108-4.737 4.064-8.517 8.801-8.41 4.737.108 8.517 4.064 8.409 8.801l-.249 10.931c-.108 4.737-4.064 8.517-8.801 8.409z"
      />
    </g>
  </StyledTaliSvg>
)
