// Basic libraries
import React from "react"
import PropTypes from "prop-types"

// Specific libraries
import BluePlanet from "../../images/planets/blue.png"
import RocketPlanet from "../../images/planets/planet-with-rocket.png"
import WordProgressBar from "../WordProgressBar"
// Styling
import { DailyProgressWrapper } from "./DailyProgressStyling"

import { useStudentStore } from "../../../store"

// component
const DailyProgressDisplay = ({ weekData, scaler = 1 }) => {
  const planet = BluePlanet
  const rocketPlanet = RocketPlanet

  return (
    <DailyProgressWrapper>
      <div className="monday">
        <WordProgressBar
          planet={weekData[0].isCurrentDay ? rocketPlanet : planet}
          progressSizeRatio={4 * scaler}
          targetLearnedWords={useStudentStore.getState().student.dailyTarget}
          realLearnedWords={weekData[0].realLearnedWords}
          isHidden={weekData[0].isFutureDay}
        />
        <h3>Montag</h3>
      </div>
      <div className="tuesday">
        <WordProgressBar
          planet={weekData[1].isCurrentDay ? rocketPlanet : planet}
          progressSizeRatio={6 * scaler}
          targetLearnedWords={useStudentStore.getState().student.dailyTarget}
          realLearnedWords={weekData[1].realLearnedWords}
          isHidden={weekData[1].isFutureDay}
        />
        <h3>Dienstag</h3>
      </div>
      <div className="wednesday">
        <WordProgressBar
          planet={weekData[2].isCurrentDay ? rocketPlanet : planet}
          progressSizeRatio={8 * scaler}
          targetLearnedWords={useStudentStore.getState().student.dailyTarget}
          realLearnedWords={weekData[2].realLearnedWords}
          isHidden={weekData[2].isFutureDay}
        />
        <h3>Mittwoch</h3>
      </div>
      <div className="thursday">
        <WordProgressBar
          planet={weekData[3].isCurrentDay ? rocketPlanet : planet}
          progressSizeRatio={10 * scaler}
          targetLearnedWords={useStudentStore.getState().student.dailyTarget}
          realLearnedWords={weekData[3].realLearnedWords}
          isHidden={weekData[3].isFutureDay}
        />
        <h3>Donnerstag</h3>
      </div>
      <div className="friday">
        <WordProgressBar
          planet={weekData[4].isCurrentDay ? rocketPlanet : planet}
          progressSizeRatio={12 * scaler}
          targetLearnedWords={useStudentStore.getState().student.dailyTarget}
          realLearnedWords={weekData[4].realLearnedWords}
          isHidden={weekData[4].isFutureDay}
        />
        <h3>Freitag</h3>
      </div>
    </DailyProgressWrapper>
  )
}
// component-end

DailyProgressDisplay.propTypes = {
  weekData: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string,
      isCurrentDay: PropTypes.bool,
      isFutureDay: PropTypes.bool,
      realLearnedWords: PropTypes.number,
      targetLearnedWords: PropTypes.number,
    })
  ).isRequired,
  scaler: PropTypes.number,
}

export default DailyProgressDisplay
