import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"

import TaliDisplay from "./TaliDisplay"

const preventEvents = event => {
  // we need this to prevent the keyboard from reacting to key events
  // originating from the dialog.
  event.stopPropagation()
  if (event.type === "keydown") {
    event.preventDefault()
  } else {
    event.target.click()
  }
}

const TaliContainer = props => {
  const defaultRef = useRef(null)
  useEffect(() => {
    if (!defaultRef.current) return
    const target = defaultRef.current
    target.focus()
    target.addEventListener("keydown", preventEvents)
    target.addEventListener("keyup", preventEvents)
    return () => {
      target.removeEventListener("keydown", preventEvents)
      target.removeEventListener("keyup", preventEvents)
    }
  }, [defaultRef])

  return <TaliDisplay defaultRef={defaultRef} {...props} />
}

TaliContainer.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  mood: PropTypes.oneOf(["happy", "curious"]),
  children: PropTypes.node,
  buttonLabel: PropTypes.string,
  buttonAction: PropTypes.func,
  background: PropTypes.node,
  backdrop: PropTypes.bool,
  float: PropTypes.bool,
  position: PropTypes.oneOf(["top", "bottom"]),
  messageStyle: PropTypes.oneOf(["white", "glow"]),
}

export default TaliContainer
